import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/store/adjustments', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOne(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/store/adjustments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, adjustmentData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/store/adjustments', adjustmentData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, adjustmentData) {
      return new Promise((resolve, reject) => {
        const url = `/store/adjustments/${adjustmentData.id}`
        axios
          .put(url, adjustmentData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, id) {
      return new Promise((resolve, reject) => {
        const url = `/store/adjustments/${id}`
        axios
          .delete(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
